.select-custom{
  padding: 14px 50px 14px 20px;
    border-radius: 12px;
    font-size: 18px;
    box-shadow: none;
    border-color: #ced4da;
    width: 100%;
    color: var(--body-text-color);
}

@media screen and (max-width: 767px) {
  .pb-70{
    padding-bottom: 70px !important;
  }
}
.btn-sm{
  padding: 5px 10px !important;
    font-size: 12px !important;
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.9375rem;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(67, 89, 113, 0.1);
  box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, 0.45);
  border-radius: 0.5rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 1.25rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 1.25rem 1.25rem;
  color: #697a8d;
  background-color: transparent;
  background-clip: padding-box;
  border-bottom: 0 solid transparent;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.toast-header .btn-close {
  margin-right: -0.625rem;
  margin-left: 1.25rem;
}

.toast-body {
  padding: 1.25rem;
  word-wrap: break-word;
}
.toast.bs-toast {
  z-index: 99999999999999;
  position: fixed;
}

.bg-secondary.toast, .bg-secondary.bs-toast {
  color: #fff;
  background-color: rgba(133, 146, 163, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(133, 146, 163, 0.4);
}
.bg-secondary.toast .toast-header, .bg-secondary.bs-toast .toast-header {
  color: #fff;
}
.bg-secondary.toast .toast-header .btn-close, .bg-secondary.bs-toast .toast-header .btn-close {
  background-color: #8592a3 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(133, 146, 163, 0.4) !important;
}

.bg-success.toast, .bg-success.bs-toast {
  color: #fff;
  background-color: rgba(113, 221, 55, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(113, 221, 55, 0.4);
}
.bg-success.toast .toast-header, .bg-success.bs-toast .toast-header {
  color: #fff;
}
.bg-success.toast .toast-header .btn-close, .bg-success.bs-toast .toast-header .btn-close {
  background-color: #71dd37 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(113, 221, 55, 0.4) !important;
}

.bg-info.toast, .bg-info.bs-toast {
  color: #fff;
  background-color: rgba(3, 195, 236, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(3, 195, 236, 0.4);
}
.bg-info.toast .toast-header, .bg-info.bs-toast .toast-header {
  color: #fff;
}
.bg-info.toast .toast-header .btn-close, .bg-info.bs-toast .toast-header .btn-close {
  background-color: #03c3ec !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(3, 195, 236, 0.4) !important;
}

.bg-warning.toast, .bg-warning.bs-toast {
  color: #fff;
  background-color: rgba(255, 171, 0, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(255, 171, 0, 0.4);
}
.bg-warning.toast .toast-header, .bg-warning.bs-toast .toast-header {
  color: #fff;
}
.bg-warning.toast .toast-header .btn-close, .bg-warning.bs-toast .toast-header .btn-close {
  background-color: #ffab00 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(255, 171, 0, 0.4) !important;
}

.bg-danger.toast, .bg-danger.bs-toast {
  color: #fff;
  background-color: rgba(255, 62, 29, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(255, 62, 29, 0.4);
}
.bg-danger.toast .toast-header, .bg-danger.bs-toast .toast-header {
  color: #fff;
}
.bg-danger.toast .toast-header .btn-close, .bg-danger.bs-toast .toast-header .btn-close {
  background-color: #ff3e1d !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(255, 62, 29, 0.4) !important;
}

.bg-light.toast, .bg-light.bs-toast {
  color: #fff;
  background-color: rgba(252, 253, 253, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(252, 253, 253, 0.4);
}
.bg-light.toast .toast-header, .bg-light.bs-toast .toast-header {
  color: #fff;
}
.bg-light.toast .toast-header .btn-close, .bg-light.bs-toast .toast-header .btn-close {
  background-color: #fcfdfd !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(252, 253, 253, 0.4) !important;
}

.bg-dark.toast, .bg-dark.bs-toast {
  color: #fff;
  background-color: rgba(35, 52, 70, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(35, 52, 70, 0.4);
}
.bg-dark.toast .toast-header, .bg-dark.bs-toast .toast-header {
  color: #fff;
}
.bg-dark.toast .toast-header .btn-close, .bg-dark.bs-toast .toast-header .btn-close {
  background-color: #233446 !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(35, 52, 70, 0.4) !important;
}

.bg-gray.toast, .bg-gray.bs-toast {
  color: #fff;
  background-color: rgba(67, 89, 113, 0.85) !important;
  box-shadow: 0 0.25rem 1rem rgba(67, 89, 113, 0.4);
}
.bg-gray.toast .toast-header, .bg-gray.bs-toast .toast-header {
  color: #fff;
}
.bg-gray.toast .toast-header .btn-close, .bg-gray.bs-toast .toast-header .btn-close {
  background-color: rgba(67, 89, 113, 0.1) !important;
  background-image: url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate(-225.000000, -250.000000)'%3E%3Cg id='Icon-Color' transform='translate(225.000000, 250.500000)'%3E%3Cuse fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='%23fff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  box-shadow: 0 0.1875rem 0.375rem 0 rgba(67, 89, 113, 0.4) !important;
}

.bs-toast[class^=bg-],
.bs-toast[class*=" bg-"] {
  border: none;
}

.toast.bs-toast {
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 1095;
}
.toast.bs-toast .toast-header {
  padding-bottom: 0.5rem;
  position: relative;
}
.toast.bs-toast .toast-header .btn-close {
  position: absolute;
  top: -8px;
  border-radius: 0.375rem;
  padding: 0.45rem;
  background-size: 0.625em;
  transition: all 0.23s ease 0.1s;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(161, 172, 184, 0.4);
  right: 2px;
}
.toast.bs-toast .toast-header .btn-close:hover, .toast.bs-toast .toast-header .btn-close:focus, .toast.bs-toast .toast-header .btn-close:active {
  opacity: 1;
  outline: none;
}
.toast.bs-toast .toast-header ~ .toast-body {
  padding-top: 0;
}
.nav-right-account .dropdown-menu {
  border: none;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  height: 92vh;
  right: -50px;
  top: 20px;
}

  /* Custom styles for cards */
  .booking-card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding: 10px;
}
.booking-info {
    font-size: 0.8rem;
}
.booking-card img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.badge-status {
    font-size: 0.75rem;
    font-size: 0.75rem;
    line-height: 1.3;
    font-weight: 500;
}